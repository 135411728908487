import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Footer from './components/Footer';
import Navbar from './components/Navbar';
import DesprePage from './pages/Despre';
import HomePage from './pages/Home';
import NotFoundPage from './pages/NotFound';
import GaleriePage from './pages/Galerie';
import ProiectePage from './pages/Proiecte';
import ContactPage from './pages/Contact';
import './App.css';
import CalendarProiecte from './pages/CalendarProiecte';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="flex flex-col min-h-screen">
          <Navbar />
          <div className="flex-1">
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Helmet>
                      <title>Liceul Tehnologic "Danubius" Corabia</title>
                      <meta
                        name="description"
                        content='Pagina oficiala a Liceul Tehnologic "Danubius" Corabia.'
                      />
                    </Helmet>
                    <HomePage />
                  </>
                }
              />
              <Route
                path="/despre"
                element={
                  <>
                    <Helmet>
                      <title>
                        Despre - Liceul Tehnologic "Danubius" Corabia
                      </title>
                      <meta
                        name="description"
                        content='Despre - Liceul Tehnologic "Danubius" Corabia'
                      />
                    </Helmet>
                    <DesprePage />
                  </>
                }
              />
              <Route
                path="/proiecte"
                element={
                  <>
                    <Helmet>
                      <title>
                        Proiecte - Liceul Tehnologic "Danubius" Corabia
                      </title>
                      <meta
                        name="description"
                        content='Descopera proiectele realizate de Liceul Tehnologic "Danubius" Corabia'
                      />
                    </Helmet>
                    <ProiectePage />
                  </>
                }
              />
              <Route
                path="/galerie/"
                element={
                  <>
                    <Helmet>
                      <title>
                        Galerie - Liceul Tehnologic "Danubius" Corabia
                      </title>
                      <meta
                        name="description"
                        content='Galeria de poze realizate de Liceul Tehnologic "Danubius" Corabia'
                      />
                    </Helmet>
                    <GaleriePage />
                  </>
                }
              />
              <Route
                path="/contact/"
                element={
                  <>
                    <Helmet>
                      <title>
                        Contact - Liceul Tehnologic "Danubius" Corabia
                      </title>
                      <meta
                        name="description"
                        content='Datele de contact ale Liceului Tehnologic "Danubius" Corabia'
                      />
                    </Helmet>

                    <ContactPage />
                  </>
                }
              />
              <Route
                path="/calendar/"
                element={
                  <>
                    <Helmet>
                      <title>
                        Calendar - Liceul Tehnologic "Danubius" Corabia
                      </title>
                      <meta
                        name="description"
                        content='Calendarul de proiecte ale Liceului Tehnologic "Danubius" Corabia'
                      />
                    </Helmet>
                    <CalendarProiecte />
                  </>
                }
              />
              <Route
                path="*"
                element={
                  <>
                    <Helmet>
                      <title>404 - Page Not Found</title>
                      <meta name="description" content="Page not found" />
                    </Helmet>
                    <NotFoundPage />
                  </>
                }
              />
            </Routes>
          </div>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
