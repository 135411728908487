const NotFoundPage = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h1 className="text-4xl font-bold">404: Page Not Found</h1>
        <button
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
          onClick={() => (window.location.href = '/')}
        >
          Go to Home Page
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
